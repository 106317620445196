import { Renderer, Vector2 } from 'three'

export class SelectionHelper {

  private element!: HTMLDivElement
  private renderer!: Renderer

  private startPoint: Vector2
  private pointTopLeft: Vector2
  private pointBottomRight: Vector2


  private active = false
  public get isActive(): boolean {
    return this.active
  }

  constructor(renderer: Renderer, cssClassName: string) {

    this.element = document.createElement('div')
    this.element.classList.add(cssClassName)
    this.element.style.pointerEvents = 'none'

    this.renderer = renderer

    this.startPoint = new Vector2()
    this.pointTopLeft = new Vector2()
    this.pointBottomRight = new Vector2()



    // this.renderer.domElement.addEventListener('pointerdown', e => this.onPointerDown(e))
    // this.renderer.domElement.addEventListener('pointermove', e => this.onPointerMove(e))
    // this.renderer.domElement.addEventListener('pointerup', e => this.onPointerUp(e))
  }

  // private onPointerDown(event: PointerEvent) {
  //   console.log(this)
  //   this.isDown = true
  //   this.onSelectStart(event)
  // }
  // private onPointerMove(event: PointerEvent) {
  //   if (this.isDown) {
  //     this.onSelectMove(event)
  //   }
  // }
  // private onPointerUp(event: PointerEvent) {
  //   this.isDown = false
  //   this.onSelectOver()
  // }

  private dispose() {
    // this.renderer.domElement.removeEventListener('pointerdown', this.onPointerDown)
    // this.renderer.domElement.removeEventListener('pointermove', this.onPointerMove)
    // this.renderer.domElement.removeEventListener('pointerup', this.onPointerUp)
  }

  onSelectStart(event: PointerEvent) {
    if (this.active) return
    this.active = true
    this.renderer.domElement.parentElement!.appendChild(this.element)

    this.element.style.left = event.clientX + 'px'
    this.element.style.top = event.clientY + 'px'
    this.element.style.width = '0px'
    this.element.style.height = '0px'

    this.startPoint.x = event.clientX
    this.startPoint.y = event.clientY
  }

  onSelectMove(event: PointerEvent) {
    if (!this.active) return
    this.pointBottomRight.x = Math.max(this.startPoint.x, event.clientX)
    this.pointBottomRight.y = Math.max(this.startPoint.y, event.clientY)
    this.pointTopLeft.x = Math.min(this.startPoint.x, event.clientX)
    this.pointTopLeft.y = Math.min(this.startPoint.y, event.clientY)

    this.element.style.left = this.pointTopLeft.x + 'px'
    this.element.style.top = this.pointTopLeft.y + 'px'
    this.element.style.width = (this.pointBottomRight.x - this.pointTopLeft.x) + 'px'
    this.element.style.height = (this.pointBottomRight.y - this.pointTopLeft.y) + 'px'
  }

  onSelectOver() {
    if (!this.active) return
    this.active = false
    this.element.parentElement!.removeChild(this.element)
  }

}