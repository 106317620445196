







import { Component, Vue, Watch } from 'vue-property-decorator'
import { Box3, BoxGeometry, BoxHelper, CatmullRomCurve3, Color, CylinderGeometry, Euler, Fog, FogExp2, GridHelper, Group, HemisphereLight, Mesh, MeshLambertMaterial, MOUSE, Object3D, PerspectiveCamera, PlaneGeometry, Quaternion, Raycaster, Scene, SphereGeometry, TextureLoader, Vector2, Vector3, WebGLCubeRenderTarget, WebGLRenderer } from 'three'
import { degToRad, radToDeg } from 'three/src/math/MathUtils'
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls.js'
import { Line2 } from 'three/examples/jsm/lines/Line2.js'
import { LineMaterial } from 'three/examples/jsm/lines/LineMaterial.js'
import { LineGeometry } from 'three/examples/jsm/lines/LineGeometry.js'
import { TransformControls } from 'three/examples/jsm/controls/TransformControls.js'
import { SelectionBox } from 'three/examples/jsm/interactive/SelectionBox.js'
import Stats from 'three/examples/jsm/libs/stats.module'
import { SelectionHelper } from '../plugins/SelectionHelper'
import GUI, { Controller } from 'lil-gui'
import { doc, getDoc, setDoc, getFirestore } from '@firebase/firestore'
import { ClimberAddDef, Course, CourseObject, GateDef, ICourse, IObject, IPanel, IPath, IPipe, IVector, LadderAddDef, PipeLength, TimingGateDef } from '@/assets/TimingGateDef'
import { customAlphabet } from 'nanoid'

export const genCode = customAlphabet('0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZ', 5)

@Component({ components: {} })
export default class Editor extends Vue {

  private scene = new Scene()
  private camera!: PerspectiveCamera
  private renderer = new WebGLRenderer({ antialias: true, alpha: true })
  private selectionBox!: SelectionBox
  private helper!: SelectionHelper
  private raycaster = new Raycaster()
  private transformControl!: TransformControls

  private controls!: OrbitControls
  private pathMat = new LineMaterial()
  private pathGeo = new LineGeometry()
  private get center(): Vector3 {
    return new Vector3(this.params.gridWidth / 2, this.params.gridHeight / 2, 0)
  }
  // private stats = Stats()
  private camDefaultPos = new Vector3(0, 0, 100)
  private timingGate?: Object3D
  private teeGeo = new CylinderGeometry(0.53, 0.53, 2.5, 50)

  private objectGUI?: GUI
  private mainGUI?: GUI
  private plane!: Mesh
  private usingMenu = false
  private path!: Line2
  private flyThrough = false
  private flyThroughPos = 0

  private DIV_MUL = 100
  private pathSpline!: CatmullRomCurve3
  private pointHelpers: Mesh[] = []

  private selPos?: Vector3
  private selRot?: Euler
  private selSize?: IVector

  private course: Course = new Course()

  private params: any = {
    ftFOV: 100,
    ftSpeed: 3,
    gridWidth: 120,
    gridHeight: 240,
    showGrid: true,
    rotSnapDegs: 90,
    posSnap: 1,
    selRoute: {}
  }

  private loadDefaults() {
    console.log('Loading Defaults')
    this.clearCourse()
    this.addTimingGateDef({ position: this.spawnPoint() })

    this.course.paths = [this.defaultPath()]
    this.params.selRoute = this.course.paths[0]
    this.selRouteChanged()

    this.createGUI()
  }

  private clearCourse() {
    this.detachObjects()
    for (const child of this.scene.children.slice()) {
      if (child.userData.courseObject) {
        child.removeFromParent()
      }
    }
    this.pointHelpers = []
    this.timingGate = undefined
    this.rebuildLine()
  }

  private newCourse() {
    this.course = new Course({
      code: genCode(),
      paths: [this.defaultPath()]
    })
    this.$router.push(`/editor/${this.course.code}`)
  }
  private get sceneContainer(): HTMLDivElement {
    return this.$refs.three as HTMLDivElement
  }
  private get code(): string | undefined { return this.$route.params.code }
  private get override(): boolean { return this.$route.query.ul === '42' }
  private get locked(): boolean { return !this.override && (!!this.code && this.code.length === 6) }

  private loadBackground(url: string) {
    const loader = new TextureLoader()
    const texture = loader.load(url, () => {
      const rt = new WebGLCubeRenderTarget(texture.image.width, {})
      rt.fromEquirectangularTexture(this.renderer, texture)
      // rt
      this.scene.background = rt.texture
      // this.scene.rotation.y = Math.PI / 2
    })
  }

  private mounted() {
    this.scene.up.set(0, 0, 1)
    this.scene.background = new Color(0xEFEFEF)
    // this.loadBackground('https://dl.polyhaven.org/file/ph-assets/HDRIs/extra/Tonemapped%20JPG/ballroom.jpg')
    // this.scene.fog = new Fog(0xEFEFEF, 250, 600)
    this.scene.fog = new FogExp2(0xEFEFEF, 0.001)
    // const axesHelper = new AxesHelper(120)
    // this.scene.add(axesHelper)

    this.renderer.shadowMap.enabled = true
    this.renderer.setPixelRatio(window.devicePixelRatio)
    // this.renderer.setSize(window.innerWidth, window.innerHeight)
    // console.log(this.sceneContainer.parentElement.heigh)
    this.camera = new PerspectiveCamera(50, window.innerWidth / window.innerHeight, 0.1, 2000)
    this.camera.position.copy(this.camDefaultPos)
    this.camera.lookAt(this.center)
    this.camera.updateProjectionMatrix()
    this.camera.up.set(0, 0, 1)


    this.selectionBox = new SelectionBox(this.camera, this.scene)
    this.helper = new SelectionHelper(this.renderer, 'selectBox')

    this.controls = new OrbitControls(this.camera, this.renderer.domElement)
    this.controls.enableDamping = true
    // this.controls.dampingFactor = 0.1
    this.controls.zoomSpeed = 0.5
    this.controls.minDistance = 5
    this.controls.maxDistance = 400
    this.controls.maxPolarAngle = Math.PI / 2
    this.controls.mouseButtons = {
      LEFT: -1,
      MIDDLE: -1,
      RIGHT: MOUSE.ROTATE,
    }

    this.controls.target = this.center
    // this.controls.maxZoom = 2
    // this.controls.minZoom = 1
    this.controls.update()

    this.sceneContainer.appendChild(this.renderer.domElement)

    // window.addEventListener('resize', this.onWindowResize)
    document.addEventListener('pointerdown', this.onPointerDown)
    document.addEventListener('pointerup', this.onPointerUp)
    document.addEventListener('pointermove', this.onPointerMove)
    document.addEventListener('keydown', this.onKeyDown)
    document.addEventListener('keyup', this.onKeyUp)

    const hemiLight = new HemisphereLight(0xffffff, 0x080808, 1)
    hemiLight.name = "HemiLight"
    hemiLight.position.copy(this.center).setZ(250)
    this.scene.add(hemiLight)

    this.transformControl = new TransformControls(this.camera, this.renderer.domElement)
    this.transformControl.translationSnap = this.params.posSnap
    this.transformControl.rotationSnap = degToRad(this.params.rotSnapDegs)
    // this.transformControl.setScaleSnap(1)
    this.transformControl.axis = 'XYZ'
    this.transformControl.name = "Transform Controller"
    this.scene.add(this.transformControl)

    const self = this
    this.transformControl.addEventListener('dragging-changed', function (event) {
      self.controls.enabled = !event.value
    })
    this.transformControl.addEventListener('objectChange', this.objectChanged)

    this.pathMat.linewidth = 8
    this.pathMat.vertexColors = true

    this.path = new Line2(this.pathGeo, this.pathMat)
    this.path.name = "Course Path"
    this.scene.add(this.path)

    this.addPlane()

    if (this.code) {
      this.loadCourseDef(this.code)
    } else {
      this.loadDefaults()
      // this.loadCourseDef('MTS001')
    }

    // document.body.appendChild(this.stats.dom)

    this.animate()
  }

  @Watch('$route')
  routeChanged() {
    if (this.code) {
      this.loadCourseDef(this.code)
    } else {
      this.loadDefaults()
    }
  }

  private courseDoc() {
    if (this.course.code.length === 6) {
      return doc(getFirestore(), 'courses', this.course.code)
    }
    return doc(getFirestore(), 'ugcourses', this.course.code)
  }

  private async loadCourseDef(code: string) {
    this.course.code = code
    const doc = await getDoc(this.courseDoc())
    if (doc.exists()) {
      const course = new Course(doc.data())
      this.loadCourse(course)
    } else {
      this.loadDefaults()
    }
  }


  private pipe20Mat = new MeshLambertMaterial({ color: 0xEAEFE3 })
  private pipe10Mat = new MeshLambertMaterial({ color: 0xEAEFE3 })
  private pipe3Mat = new MeshLambertMaterial({ color: 0xEAEFE3 })
  private addPipeDef(def?: IPipe, parent?: Object3D) {
    const geometry = new CylinderGeometry(0.4, 0.4, def?.length ?? PipeLength.p20, 50)
    let mat = this.pipe20Mat
    switch (def?.length ?? PipeLength.p20) {
      case PipeLength.p3: {
        mat = this.pipe3Mat
        break
      }
      case PipeLength.p4: {
        mat = this.pipe3Mat
        break
      }
      case PipeLength.p10: {
        mat = this.pipe10Mat
        break
      }
      case PipeLength.p20: {
        mat = this.pipe20Mat
        break
      }
    }
    const mesh = new Mesh(geometry, mat)
    mesh.name = CourseObject.pipe
    mesh.userData.courseObject = true
    mesh.userData.type = CourseObject.pipe
    mesh.userData.length = def?.length ?? PipeLength.p20

    this.applyTransform(mesh, def)

    if (parent) {
      parent.add(mesh)
    } else {
      this.scene.add(mesh)
    }
    return mesh
  }

  private applyTransform(mesh: Object3D, def?: IObject) {
    mesh.rotation.x = degToRad(def?.rotation?.x ?? 0)
    mesh.rotation.y = degToRad(def?.rotation?.y ?? 0)
    mesh.rotation.z = degToRad(def?.rotation?.z ?? 0)

    mesh.position.x = def?.position.x ?? 0
    mesh.position.y = def?.position.y ?? 0
    mesh.position.z = def?.position.z ?? 0
  }

  private teeMat = new MeshLambertMaterial({ color: 0x888888 })
  private addTeeDef(def?: IObject, parent?: Object3D) {
    const mesh = new Mesh(this.teeGeo, this.teeMat)

    mesh.name = CourseObject.tee
    mesh.userData.courseObject = true
    mesh.userData.type = CourseObject.tee

    this.applyTransform(mesh, def)

    if (parent) {
      parent.add(mesh)
    } else {
      this.scene.add(mesh)
    }
    return mesh
  }

  private panelMat = new MeshLambertMaterial({ color: 0xFFFFFF })
  private addPanelDef(def?: IPanel, parent?: Object3D) {
    const geo = new BoxGeometry(def?.size?.x ?? 20, def?.size?.y ?? 20, def?.size?.z ?? 0.25)
    const mesh = new Mesh(geo, this.panelMat)
    mesh.name = CourseObject.panel
    mesh.userData.courseObject = true
    mesh.userData.type = CourseObject.panel
    mesh.userData.size = def?.size ?? { x: 20, y: 20, z: 0.25 }
    this.applyTransform(mesh, def)
    if (parent) {
      parent.add(mesh)
    } else {
      this.scene.add(mesh)
    }
    return mesh
  }

  private addTimingGateDef(def: IObject): Object3D {
    if (this.timingGate) {
      this.applyTransform(this.timingGate, def)
      return this.timingGate
    }
    this.timingGate = new Group()
    this.timingGate.name = CourseObject.timingGate
    this.timingGate.userData.courseObject = true
    this.timingGate.userData.type = CourseObject.timingGate

    TimingGateDef.pipes.forEach(def => this.addPipeDef(def, this.timingGate))
    TimingGateDef.tees.forEach(def => this.addTeeDef(def, this.timingGate))
    TimingGateDef.panels.forEach(def => this.addPanelDef(def, this.timingGate))


    this.applyTransform(this.timingGate, def)
    this.scene.add(this.timingGate)
    return this.timingGate
  }

  private loadCourse(c: Course) {
    this.clearCourse()
    this.course = c

    this.addTimingGateDef(this.course.timingGate)
    this.course.pipes.forEach(def => this.addPipeDef(def))
    this.course.tees.forEach(def => this.addTeeDef(def))
    this.course.gates.forEach(def => this.addGateDef(def))
    this.course.ladders.forEach(def => this.addLadderDef(def))
    this.course.climbers.forEach(def => this.addClimberDef(def))
    this.course.panels.forEach(def => this.addPanelDef(def))

    if (this.locked) {
      this.pathPointMat.visible = false
      this.path.visible = true
      this.teeMat.visible = true
    }
    if (this.course.paths.length === 0) {
      this.course.paths.push(this.defaultPath())
    }
    this.params.selRoute = this.course.paths[0]
    // let lp = this.params.selRoute.points.pop()
    // this.params.selRoute.points.unshift(lp)
    // lp = this.params.selRoute.points.pop()
    // this.params.selRoute.points.unshift(lp)
    this.createGUI()
    this.selRouteChanged()
  }

  private defaultPath() {
    const path: IPath = {
      name: 'Route 1',
      points: []
    }
    const sp = this.spawnPoint()
    sp.y = (sp.y ?? 0)
    sp.z = 10
    path.points.push({ ...sp })
    sp.y = (sp.y ?? 0) + 40
    sp.x = (sp.x ?? 0)
    path.points.push({ ...sp })
    sp.x = (sp.x ?? 0) + 20
    sp.y = (sp.y ?? 0) - 20
    path.points.push({ ...sp })
    return path
  }

  private updatePointVis() {
    if (!this.pathPointMat.visible) {
      if (this.transformControl.object?.type === CourseObject.pathPoint) {
        this.detachObjects()
      }
    }
  }

  private guiDiv!: HTMLDivElement
  private updateSnaps() {
    this.transformControl.rotationSnap = degToRad(this.params.rotSnapDegs)
    this.transformControl.translationSnap = this.params.posSnap
  }

  private createRouteDef() {
    const newDef = this.defaultPath()
    newDef.name = `Route ${this.course.paths.length + 1}`
    this.course.paths.push(newDef)
    this.params.selRoute = newDef
    this.selRouteChanged()
  }

  private deleteRouteDef() {
    if (this.params.selRoute) {
      this.course.paths = this.course.paths.filter(p => p !== this.params.selRoute)
      if (this.course.paths.length === 0) {
        this.course.paths = [this.defaultPath()]
      }
      this.params.selRoute = this.course.paths[0]
      this.selRouteChanged()
    }
  }

  private selRouteChanged() {
    console.log('loading route', this.params.selRoute)
    // need to save the old one?
    this.detachObjects()
    this.pointHelpers.slice().forEach(ph => ph.removeFromParent())

    if (this.params.selRoute) {
      this.pointHelpers = []
      for (const point of this.params.selRoute.points) {

        this.addPathPoint(point)
      }
      this.rebuildLine()
    }
    this.updateRoutesGUI()
  }

  private routesGUI?: GUI
  private get routeNames() {
    const names: any = {}
    for (let i = 0; i < this.course.paths.length; i++) {
      const p = this.course.paths[i]
      names[`${i}: ${p.name}`] = p
    }
    return names
  }
  private updateRoutesGUI() {
    this.routesGUI?.children.slice().forEach(c => c.destroy())
    this.routesGUI?.add(this, 'createRouteDef').name('Add Route').disable(this.locked)
    this.routesGUI?.add(this.params, 'selRoute', this.routeNames).name('Route').listen().onFinishChange(this.selRouteChanged)
    this.routesGUI?.add(this.params.selRoute, 'name').name('Name').listen().disable(this.locked).onFinishChange(this.updateRoutesGUI)
    this.routesGUI?.add(this.path, 'visible').name('Show Route').listen()
    this.routesGUI?.add(this.pathPointMat, 'visible').name('Show Points').listen().onFinishChange(this.updatePointVis)
    this.routesGUI?.add(this, 'deleteRouteDef').name('Delete Route').disable(this.locked)
  }
  private createGUI() {
    this.mainGUI?.destroy()
    this.routesGUI?.destroy()

    if (!this.guiDiv) {
      this.guiDiv = document.createElement("div")
      this.guiDiv.className += ' guiLeft'
      document.body.insertBefore(this.guiDiv, document.body.firstElementChild)
    }
    // const locked = !this.locked

    this.mainGUI = new GUI({ container: this.guiDiv })
    this.mainGUI.add(this, 'startFlyThrough').name('Toggle Fly Through')

    const ccf = this.mainGUI.addFolder('Course')
    ccf.add(this.course, 'name').name('Name').listen().disable(this.locked)
    ccf.add(this.course, 'author').name('Author').listen().disable(this.locked)
    ccf.add(this.course, 'code').name('Code').listen().disable()
    ccf.add(this, 'newCourse').name('New Course')
    if (!this.locked) {
      ccf.add(this, 'loadDefaults').name('Clear Course')
      ccf.add(this, 'saveCourse').name('Save Course')
    }
    ccf.close()

    const mf = this.mainGUI.addFolder('Materials')
    mf.add(this.course, 'mtsSets', 0, 10, 1).name('MTS Sets').listen().disable(this.locked)
    mf.add(this, 'pipes20').name('Pipes - 20"').listen().disable()
    mf.add(this, 'pipes10').name('Pipes - 10"').listen().disable()
    mf.add(this, 'pipes3').name('Pipes - 3"').listen().disable()
    mf.add(this, 'teesCount').name('Tees').listen().disable()
    mf.close()

    this.routesGUI = this.mainGUI.addFolder('Routes')
    this.updateRoutesGUI()
    this.routesGUI.close()

    const sf = this.mainGUI.addFolder('Settings')
    sf.add(this.params, 'ftFOV', 75, 130, 1).listen().name('Fly Through FOV')
    sf.add(this.params, 'ftSpeed', 1, 10, 1).listen().name('Fly Through Speed')

    if (!this.locked) {
      sf.add(this.params, 'rotSnapDegs', [90, 45, 15, 5]).listen().name('Rotation Snap').onFinishChange(this.updateSnaps)
      sf.add(this.params, 'posSnap', [1, 5, 10]).listen().name('Position Snap').onFinishChange(this.updateSnaps)
      sf.add(this.teeMat, 'visible').listen().name('Show Tees')//.onFinishChange(this.updateTeesVis)
      sf.add(this.params, 'gridWidth', 120, 600, 10).name('Grid Width').onFinishChange(this.addPlane)
      sf.add(this.params, 'gridHeight', 120, 1120, 10).name('Grid Height').onFinishChange(this.addPlane)
    }
    sf.add(this, 'getPic').name('Picture')
    sf.close()

    const cf = this.mainGUI.addFolder('Colors')
    cf.addColor(this.planeMat, 'color').name('Floor Color').listen()
    cf.addColor(this.pipe20Mat, 'color').name('20" Color').listen()
    cf.addColor(this.pipe10Mat, 'color').name('10" Color').listen()
    cf.addColor(this.pipe3Mat, 'color').name('3" Color').listen()
    cf.addColor(this.teeMat, 'color').name('Tee Color').listen()
    cf.addColor(this.panelMat, 'color').name('Panel Color').listen()
    cf.add(this.params, 'showGrid').name('Show Grid').listen().onFinishChange(this.updateGridVis)
    cf.add(this, 'resetColors').name('Reset Colors')
    cf.add(this, 'separateColors').name('Separate Colors')
    cf.close()

    if (!this.locked) {
      const pf = this.mainGUI.addFolder('Palette')
      pf.add(this, 'addGate').name('Add Gate')
      pf.add(this, 'addLadder').name('Add Ladder')
      pf.add(this, 'addClimber').name('Add Climber')
      pf.add(this, 'addPipe20').name('Add 20"')
      pf.add(this, 'addPipe10').name('Add 10"')
      pf.add(this, 'addPipe3').name('Add 3"')
      pf.add(this, 'addTee').name('Add Tee')
      pf.add(this, 'addPanel').name('Add Panel')
      pf.close()
    }
    this.mainGUI.domElement.addEventListener('pointerover', this.onPointerOverGUI)
    this.mainGUI.domElement.addEventListener('pointerout', this.onPointerOutGUI)
  }

  private updateGridVis() {
    this.gridHelper.visible = this.params.showGrid
  }
  private addPipe20() {
    this.selectObject(this.addPipeDef({ length: PipeLength.p20, position: this.spawnPoint() }))
  }
  private addPipe10() {
    this.selectObject(this.addPipeDef({ length: PipeLength.p10, position: this.spawnPoint() }))
  }
  private addPipe3() {
    this.selectObject(this.addPipeDef({ length: PipeLength.p3, position: this.spawnPoint() }))
  }
  private addGate() {
    this.selectObject(this.addGateDef({ position: this.spawnPoint() }))
  }
  private addLadder() {
    this.selectObject(this.addLadderDef({ position: this.spawnPoint() }))
  }
  private addPanel() {
    this.selectObject(this.addPanelDef({ position: this.spawnPoint() }))
  }
  private addClimber() {
    this.selectObject(this.addClimberDef({ position: this.spawnPoint() }))
  }
  private addTee() {
    this.selectObject(this.addTeeDef({ position: this.spawnPoint() }))
  }

  private spawnPoint(): IVector {
    const sp = (this.timingGate?.position ?? this.center).clone().sub(new Vector3(0, 20, 0))
    return { x: sp.x, y: sp.y, z: sp.z }
  }

  private addGateDef(def?: IObject) {
    const object = new Group()
    object.name = CourseObject.gate
    object.userData.courseObject = true
    object.userData.type = CourseObject.gate
    GateDef.pipes.forEach(d => this.addPipeDef(d, object))
    GateDef.tees.forEach(d => this.addTeeDef(d, object))
    this.applyTransform(object, def)
    this.scene.add(object)
    return object
  }
  private addLadderDef(def?: IObject) {
    const object = new Group()
    object.name = CourseObject.ladder
    object.userData.courseObject = true
    object.userData.type = CourseObject.ladder
    GateDef.pipes.forEach(d => this.addPipeDef(d, object))
    GateDef.tees.forEach(d => this.addTeeDef(d, object))
    LadderAddDef.pipes.forEach(d => this.addPipeDef(d, object))
    LadderAddDef.tees.forEach(d => this.addTeeDef(d, object))
    this.applyTransform(object, def)
    this.scene.add(object)
    return object
  }
  private addClimberDef(def?: IObject) {
    const object = new Group()
    object.name = CourseObject.climber
    object.userData.courseObject = true
    object.userData.type = CourseObject.climber
    GateDef.pipes.forEach(d => this.addPipeDef(d, object))
    GateDef.tees.forEach(d => this.addTeeDef(d, object))
    LadderAddDef.pipes.forEach(d => this.addPipeDef(d, object))
    LadderAddDef.tees.forEach(d => this.addTeeDef(d, object))
    ClimberAddDef.pipes.forEach(d => this.addPipeDef(d, object))
    ClimberAddDef.tees.forEach(d => this.addTeeDef(d, object))
    this.applyTransform(object, def)
    this.scene.add(object)
    return object
  }

  private get pipes20(): string {
    let p20s = this.pipes.filter(p => (p.userData.length === PipeLength.p20)).length
    p20s += this.gates.length * 4
    p20s += this.ladders.length * 7
    p20s += this.climbers.length * 10
    if (this.course.mtsSets < 1) {
      return `${p20s}`
    }
    const rem = (40 * this.course.mtsSets) - p20s
    return `${p20s} (${rem})`
  }
  private get pipes10(): string {
    const p10s = this.pipes.filter(p => (p.userData.length === PipeLength.p10)).length
    if (this.course.mtsSets < 1) {
      return `${p10s}`
    }
    const rem = (4 * this.course.mtsSets) - p10s
    return `${p10s} (${rem})`
  }
  private get courseObjects(): Object3D[] {
    return this.scene.children.filter(c => c.userData.courseObject)
  }
  private get pipes(): Object3D[] {
    return this.courseObjects.filter(c => c.userData.type === CourseObject.pipe)
  }
  private get tees(): Object3D[] {
    return this.courseObjects.filter(c => c.userData.type === CourseObject.tee)
  }
  private get gates(): Object3D[] {
    return this.courseObjects.filter(c => c.userData.type === CourseObject.gate)
  }
  private get ladders(): Object3D[] {
    return this.courseObjects.filter(c => c.userData.type === CourseObject.ladder)
  }
  private get climbers(): Object3D[] {
    return this.courseObjects.filter(c => c.userData.type === CourseObject.climber)
  }
  private get pipes3(): string {
    let p3s = this.pipes.filter(p => (p.userData.length === PipeLength.p3)).length
    p3s += this.gates.length * 2
    p3s += this.ladders.length * 2
    p3s += this.climbers.length * 2
    if (this.course.mtsSets < 1) {
      return `${p3s}`
    }
    const rem = (12 * this.course.mtsSets) - p3s
    return `${p3s} (${rem})`
  }
  private get teesCount(): string {
    let tc = this.tees.length
    tc += this.gates.length * 6
    tc += this.ladders.length * 8
    tc += this.climbers.length * 10
    if (this.course.mtsSets < 1) {
      return `${tc}`
    }
    const rem = (57 * this.course.mtsSets) - tc
    return `${tc} (${rem})`
  }
  private resetColors() {
    this.planeMat.color.setHex(0x555555) //479e56
    this.pipe3Mat.color.setHex(0xEAEFE3)
    this.pipe10Mat.color.setHex(0xEAEFE3)
    this.pipe20Mat.color.setHex(0xEAEFE3)
    this.teeMat.color.setHex(0x888888)
    this.panelMat.color.setHex(0xFFFFFF)
    window.resizeTo(1280, 720)
  }

  private separateColors() {
    this.pipe3Mat.color.setHex(0x83C3E4)
    this.pipe10Mat.color.setHex(0x83C392)
    this.pipe20Mat.color.setHex(0xEAEFE3)
  }

  private planeMat = new MeshLambertMaterial({ color: 0x555555 })
  private infPlaneMat = new MeshLambertMaterial({ color: 0x242424 })
  private gridHelper: GridHelper = new GridHelper(100, 100 / 5, 0xCCCCCC, 0x000000)
  private addPlane() {
    if (this.plane) {
      this.plane.removeFromParent()
    }
    const width = this.params.gridWidth
    const height = this.params.gridHeight
    const planeGeo = new PlaneGeometry(width, height)
    this.plane = new Mesh(planeGeo, this.planeMat)
    this.plane.position.x = width / 2
    this.plane.position.z = -0.6
    this.plane.position.y = height / 2
    this.plane.name = "Ground"
    this.scene.add(this.plane)

    const size = Math.max(width, height)
    this.gridHelper = new GridHelper(size, size / 5, 0xcccccc, 0x000000)
    this.gridHelper.position.z = 0.1
    this.gridHelper.rotation.x = degToRad(90)
    this.gridHelper.visible = this.params.showGrid
    // this.gridHelper.
    this.plane.name = "Grid"
    this.plane.add(this.gridHelper)

    const infGeo = new PlaneGeometry(50000, 50000)
    const infplane = new Mesh(infGeo, this.infPlaneMat)
    infplane.position.x = width / 2
    infplane.position.z = -10
    infplane.position.y = height / 2
    infplane.name = "Inf"
    this.plane.add(infplane)
  }


  private objectChanged() {
    this.rebuildLine()
  }

  private pathPointMat = new MeshLambertMaterial({ color: 0xFFFFFF, visible: false })
  private addPathPoint(p: IVector, idx?: number): Object3D {
    const point = new Vector3(p.x, p.y, p.z)
    const geometry = new SphereGeometry(2)
    const object = new Mesh(geometry, this.pathPointMat)
    object.position.copy(point)
    object.castShadow = true
    object.receiveShadow = true
    object.name = CourseObject.pathPoint
    object.userData.courseObject = true
    object.userData.type = CourseObject.pathPoint
    this.scene.add(object)

    if (idx && idx < this.pointHelpers.length) {
      this.pointHelpers.splice(idx, 0, object)
    } else {
      this.pointHelpers.push(object)
    }
    return object
  }

  private get pathPoints(): Vector3[] {
    return this.pointHelpers.map(ph => {
      return ph.getWorldPosition(new Vector3()).round()
    })
  }

  private rebuildLine() {
    const pps = this.pathPoints
    this.pathSpline = new CatmullRomCurve3(pps, true, 'catmullrom', 0.8)
    // sync the editor values back to the current path
    if (this.params.selRoute) {
      this.params.selRoute.points = pps.map(p => {
        return { x: p.x, y: p.y, z: p.z }
      })
    }

    const divisions = this.DIV_MUL * pps.length
    const positions = []
    const colors = []
    const point = new Vector3()
    const color = new Color()
    for (let i = 0; i < divisions; i++) {
      const t = i / divisions
      this.pathSpline.getPoint(t, point)
      positions.push(point.x, point.y, point.z)
      color.setHSL(t, 1.0, 0.5)
      colors.push(color.r, color.g, color.b)
    }

    this.pathGeo = new LineGeometry()
    this.path.geometry = this.pathGeo
    if (pps.length) {
      this.pathGeo.setPositions(positions)
      this.pathGeo.setColors(colors)
      this.path.computeLineDistances()
    }
  }

  private findPointPosition(point: Vector3) {
    const divisions = this.DIV_MUL * this.pathPoints.length
    for (let i = 0; i < divisions; i++) {
      const t = i / divisions
      const pos = this.pathSpline.getPoint(t)
      const d = pos.distanceTo(point)
      if (d < 2) {
        const x = Math.floor(i / this.DIV_MUL)
        this.selectObject(this.addPathPoint({ x: pos.x, y: pos.y, z: pos.z }, x + 1))
        this.rebuildLine()
        break
      }
    }
  }

  private startFlyThrough() {
    if (!this.flyThrough) {
      if (!this.pathSpline || this.pathSpline.points.length < 2) {
        return
      }
    }
    this.flyThrough = !this.flyThrough
    this.flyThroughPos = 0
    if (this.flyThrough) {
      this.pathPointMat.visible = false
      this.detachObjects()
      this.mainGUI?.close()
      this.camera.fov = this.params.ftFOV
    } else {
      this.camera.fov = 55
      this.camera.position.copy(this.camDefaultPos)
      this.camera.lookAt(this.center)
      this.mainGUI?.open()
    }
    this.camera.updateProjectionMatrix()
  }

  private animate() {
    requestAnimationFrame(this.animate)
    this.resizeCanvasToContainerSize()

    // this.stats.update()

    if (this.flyThrough) {
      this.flyThroughPos += 0.0002 * this.params.ftSpeed
      if (this.flyThroughPos > 1) {
        this.flyThroughPos = 0
        this.mainGUI?.open()
      }

      const t = this.pathSpline.getUtoTmapping(this.flyThroughPos, NaN)
      const loc = this.pathSpline.getPoint(t)
      const to = this.pathSpline.getPoint(t + 0.03)
      this.camera.position.copy(loc)
      // this.camera.zoom = 0
      // this.camera.fov = 110
      this.camera.lookAt(to)
    }

    if (this.getImageData) {
      // Prepare the scene
      // this.scene.background = null

      this.renderer.render(this.scene, this.camera)
      const pngData = this.renderer.domElement.toDataURL()

      this.scene.background = new Color(0xFFFFFF)

      const a = document.createElement('a')
      a.href = pngData.replace("image/png", "image/octet-stream")
      a.download = 'Image.png'
      a.click()

      this.getImageData = false
    } else {

      this.renderer.render(this.scene, this.camera)
    }

    this.selBox?.update()
  }
  private getImageData = false
  private getPic() {
    this.getImageData = true
  }

  private onPointerOverGUI(event: PointerEvent) {
    this.usingMenu = true
  }
  private onPointerOutGUI(event: PointerEvent) {
    this.usingMenu = false
  }
  private updateRaycaster(event: PointerEvent) {
    this.raycaster.setFromCamera(this.pointerPos, this.camera)
  }
  private onPointerDown(event: PointerEvent) {
    this.updatePointPosition(event)
    if (this.locked) { return }
    if (this.usingMenu) { return }
    if (this.transformControl.dragging) { return }

    if (event.button === 0) {
      this.helper.onSelectStart(event)
      this.selectionBox.startPoint.set(this.pointerPos.x, this.pointerPos.y, 0.5)
    }
  }

  private onPointerMove(event: PointerEvent) {
    this.updatePointPosition(event)
    if (this.locked) { return }
    if (this.transformControl.dragging) { return }

    if (this.helper.isActive) {
      this.helper.onSelectMove(event)
      this.selectionBox.endPoint.set(this.pointerPos.x, this.pointerPos.y, 0.5)
    }
  }

  private onPointerUp(event: PointerEvent) {
    this.updatePointPosition(event)
    if (this.locked) { return }
    if (this.usingMenu) { return }

    if (event.button === 2) {
      if (this.pathPointMat.visible) {
        this.updateRaycaster(event)
        const intersects = this.raycaster.intersectObject(this.path, false)
        if (intersects.length > 0) {
          this.findPointPosition(intersects[0].point)
        }
      }
      return
    }

    if (event.button === 0) {
      if (this.helper.isActive) {
        this.helper.onSelectOver()
        this.selectionBox.endPoint.set(this.pointerPos.x, this.pointerPos.y, 0.5)
        const selection = this.selectionBox.select().filter(obj => obj.userData.courseObject)
        if (selection.length > 0) {
          // get to the top items only and remove dups
          let tlos = selection.map(obj => this.findTLO(obj)).filter(obj => obj.userData.courseObject)
          tlos = [...new Map(tlos.map(v => [v.uuid, v])).values()]
          if (tlos.length === 1) {
            const tlo = tlos[0]
            if (tlo.userData.type === CourseObject.pathPoint) {
              if (this.pathPointMat.visible) {
                this.selectObject(tlo)
              }
            } else {
              this.selectObject(tlo)
            }
          } else {
            if (!this.pathPointMat.visible) {
              tlos = tlos.filter(it => it.userData.type !== CourseObject.pathPoint)
            }
            this.selectObjects(tlos)
          }
        }
        else {
          this.updateRaycaster(event)
          const ints = this.raycaster.intersectObjects(this.scene.children, true)
          // Filter out all non-course objects and locked objects
          const courseObjects = ints.map(i => i.object).filter(obj => obj.userData.courseObject)

          if (courseObjects.length > 0) {
            const tlo = this.findTLO(courseObjects[0])
            if (tlo.userData.courseObject) {
              if (this.pathPointMat.visible || tlo.userData.type !== CourseObject.pathPoint) {
                this.selectObject(tlo)
              }
            }
          }
          else {
            this.detachObjects()
          }
        }
      }
    }
  }

  private findTLO(object: Object3D) {
    let tlo = object
    while (tlo.parent && tlo.parent !== this.scene) {
      tlo = tlo.parent
    }
    return tlo
  }

  private onKeyDown(event: KeyboardEvent) {
    if (event.key === 'h') {
      this.camera.position.copy(this.camDefaultPos)
      this.camera.zoom = 1
      this.controls.target = this.center
      this.controls.update()
    }


    if (this.locked) { return }

    if (event.key === 'f') {
      if (this.selPos) {
        this.controls.target = this.selPos
        this.controls.update()
      }
    }

    if (event.key === 'd') {
      if (this.transformControl.object) {
        this.duplicateObject(this.transformControl.object)
      }
    }

    if (event.key === 'w') {
      this.transformControl.setMode('translate')
    }
    if (event.key === 'e') {
      this.transformControl.setMode('rotate')
    }
    if (event.key === 'r') {
      // if (this.transformControl.object) {
      //   const obj = this.transformControl.object
      //   if (obj.userData.type === CourseObject.panel) {
      //     this.transformControl.setMode('scale')
      //   }
      // }
    }
    if (event.key === 'g') {
      if (this.transformControl.object) {
        const obj = this.transformControl.object
        if (!obj.userData.tempGroup) {
          obj.position.z = 0
        }
      }
    }
    if (event.key === 'l') {
      if (this.transformControl.object) {
        const obj = this.transformControl.object
        if (!obj.userData.tempGroup) {
          obj.userData.locked = !obj.userData.locked
          if (obj.userData.locked) {
            this.detachObjects()
          }
        }
      }
    }

    if (event.key === 'a' && event.ctrlKey) {
      if (this.pathPointMat.visible) {
        this.selectObjects(this.pointHelpers)
      } else {
        const allThings = this.gates.concat(this.tees).concat(this.pipes).concat(this.ladders).concat(this.climbers)
        if (this.timingGate) {
          allThings.push(this.timingGate)
        }
        this.selectObjects(allThings)
      }
    }

    if (event.key === 'Backspace') {
      console.log('Backspace')
      if (this.transformControl.object) {
        this.deleteObject(this.transformControl.object)
      }
    }

    // if (event.key === 'G' && event.shiftKey) {
    //   if (this.transformControl.object) {
    //     const obj = this.transformControl.object
    //     if (obj.children.length > 1) {
    //       const group = new Group()
    //       const center = this.getCenterPoint(obj.children)
    //       group.position.copy(center)
    //       for (const child of obj.children.slice()) {
    //         group.add(child)
    //         // child.position.sub(center)
    //       }
    //       this.scene.add(group)
    //       this.selectObject(group)
    //     }
    //   }
    // }
  }

  private deleteObject(object: Object3D) {
    if (object.userData.tempGroup) {
      for (const child of object.children.slice()) {
        this.deleteObject(child)
      }
      return
    }
    this.detachObjects()

    if (object.userData.courseObject) {
      if (object.userData.type === CourseObject.pipe
        || object.userData.type === CourseObject.tee
        || object.userData.type === CourseObject.gate
        || object.userData.type === CourseObject.ladder
        || object.userData.type === CourseObject.climber
      ) {
        object.removeFromParent()
      } else if (object.userData.type === CourseObject.pathPoint) {
        this.pointHelpers = this.pointHelpers.filter(ph => ph !== object)
        object.removeFromParent()
        this.rebuildLine()
      }
    }
  }

  private getCenterPoint(objects: Object3D[]) {
    const b = new Box3()
    objects.forEach(obj => b.expandByObject(obj))
    b.min.floor()
    b.max.ceil()
    b.min.x = b.min.x - b.min.x % 2
    b.min.y = b.min.y - b.min.y % 2
    b.min.z = b.min.z - b.min.z % 2

    b.max.x = b.max.x - b.max.x % 2
    b.max.y = b.max.y - b.max.y % 2
    b.max.z = b.max.z - b.max.z % 2
    return b.getCenter(new Vector3())
  }

  private onKeyUp(event: KeyboardEvent) {
    if (this.locked) { return }
  }

  private detachObjects() {
    if (this.transformControl.object) {
      // console.log('Detach: ', this.transformControl.object.name)
      if (this.transformControl.object.userData.tempGroup) {
        const obj = this.transformControl.object
        this.transformControl.detach()
        const pos = obj.position
        for (const child of obj.children.slice()) {
          this.scene.add(child)
          child.position.add(pos)
        }
        obj.removeFromParent()
      } else {
        this.transformControl.detach()
        this.transformControl.setMode('translate')
      }
      this.transformControl.object = undefined
    }
    if (this.selBox) {
      this.selBox.removeFromParent()
    }
    if (this.objectGUI) {
      this.objectGUI.destroy()
      this.objectGUI = undefined
    }
  }

  private duplicateObject(object: Object3D): Object3D | undefined {
    if (object.userData.tempGroup) {
      const dups: Object3D[] = []
      for (const child of object.children.slice()) {
        const dup = this.duplicateObject(child)
        if (dup) {
          dups.push(dup)
        }
      }
      this.selectObjects(dups)
      return
    }

    if (!object.userData.courseObject) { return }

    const quaternion = new Quaternion()
    object.getWorldQuaternion(quaternion)
    const rot = new Euler()
    rot.setFromQuaternion(quaternion)
    const pos = object.getWorldPosition(new Vector3())
    const def: IObject = {
      position: { x: pos.x, y: pos.y + 5, z: pos.z },
      rotation: { x: radToDeg(rot.x), y: (rot.y), z: radToDeg(rot.z) }
    }
    switch (object.userData.type) {
      case CourseObject.pipe: {
        (def as IPipe).length = object.userData.length ?? PipeLength.p20
        return this.selectObject(this.addPipeDef(def))
      }
      case CourseObject.panel: {
        (def as IPanel).size = object.userData.size
        return this.selectObject(this.addPanelDef(def))
      }
      case CourseObject.tee: {
        return this.selectObject(this.addTeeDef(def))
      }
      case CourseObject.gate: {
        return this.selectObject(this.addGateDef(def))
      }
      case CourseObject.ladder: {
        return this.selectObject(this.addLadderDef(def))
      }
      case CourseObject.climber: {
        return this.selectObject(this.addClimberDef(def))
      }
    }
  }

  private selectObjects(objects: Object3D[]) {
    if (objects.length === 0) return
    const tempGroup = new Group()
    tempGroup.name = `Group - ${objects.length}`
    tempGroup.userData.tempGroup = true
    const center = this.getCenterPoint(objects)
    tempGroup.position.copy(center)
    objects.forEach(obj => obj.position.sub(tempGroup.position))
    for (const obj of objects) {
      tempGroup.add(obj)
    }
    this.scene.add(tempGroup)
    this.selectObject(tempGroup)
  }

  private selBox?: BoxHelper
  private selectObject(object: Object3D): Object3D | undefined {
    if (object !== this.transformControl.object) {
      this.detachObjects()

      if (object) {
        console.log('Attaching: ', object.name)
        const locked = !!object.userData.locked
        object.userData.locked = locked

        this.selBox = new BoxHelper(object)
        this.scene.add(this.selBox)

        this.transformControl.attach(object)
        this.transformControl.enabled = !locked
        this.transformControl.visible = !locked

        this.selPos = object.position
        this.selRot = object.rotation
        if (this.mainGUI) {
          this.objectGUI = this.mainGUI.addFolder('Properties')
          this.objectGUI.add(object.userData, 'locked').name('Locked').listen().onChange(this.objectChanged)
          this.objectGUI.add(this.selPos, 'x').name('X').listen().enable(!locked).onChange(this.objectChanged)
          this.objectGUI.add(this.selPos, 'y').name('Y').listen().enable(!locked).onChange(this.objectChanged)
          this.objectGUI.add(this.selPos, 'z').name('Z').listen().enable(!locked).onChange(this.objectChanged)

          if (object.userData.type === CourseObject.panel) {
            this.selSize = object.userData.size ?? { x: 20, y: 20, z: 0.25 }
            this.objectGUI.add(this.selSize!, 'x').name('W').listen().enable(!locked).onChange(this.updateObjectSize)
            this.objectGUI.add(this.selSize!, 'y').name('H').listen().enable(!locked).onChange(this.updateObjectSize)
            this.objectGUI.add(this.selSize!, 'z').name('D').listen().enable(!locked).onChange(this.updateObjectSize)
          }
        }

        return object
      }
    }
  }

  private updateObjectSize() {
    if (this.transformControl.object && this.selSize) {
      const obj = this.transformControl.object
      if (obj.userData.type === CourseObject.panel) {
        const mesh = obj as Mesh
        mesh.geometry = new BoxGeometry(this.selSize.x, this.selSize.y, this.selSize.z)
      }
    }
  }

  private async saveCourse() {
    this.detachObjects()

    let timingGate: IObject = { position: {} }
    const pipes: IPipe[] = []
    const tees: IObject[] = []
    const gates: IObject[] = []
    const ladders: IObject[] = []
    const climbers: IObject[] = []
    const panels: IPanel[] = []
    const paths: IPath[] = this.course.paths
    // paths[0].points = paths[0].points.reverse()
    for (const child of this.scene.children) {
      if (child.userData.courseObject) {
        const quaternion = new Quaternion()
        child.getWorldQuaternion(quaternion)
        const rotation = new Euler()
        rotation.setFromQuaternion(quaternion)

        const def: IObject = {
          position: {
            x: Math.round(child.position.x),
            y: Math.round(child.position.y),
            z: Math.round(child.position.z)
          },
          rotation: {
            x: Math.round(radToDeg(rotation.x)),
            y: Math.round(radToDeg(rotation.y)),
            z: Math.round(radToDeg(rotation.z))
          }
        }
        if (def.position.x === 0) delete def.position.x
        if (def.position.y === 0) delete def.position.y
        if (def.position.z === 0) delete def.position.z

        if (def.rotation?.x === 0) delete def.rotation.x
        if (def.rotation?.y === 0) delete def.rotation.y
        if (def.rotation?.z === 0) delete def.rotation.z
        if (def.rotation?.x === undefined && def.rotation?.y === undefined && def.rotation?.z === undefined) {
          delete def.rotation
        }

        if (child.userData.type === CourseObject.pipe) {
          (def as IPipe).length = child.userData.length
          pipes.push(def)
        } else if (child.userData.type === CourseObject.panel) {
          (def as IPanel).size = child.userData.size
          panels.push(def)
        } else if (child.userData.type === CourseObject.tee) {
          tees.push(def)
        } else if (child.userData.type === CourseObject.gate) {
          gates.push(def)
        } else if (child.userData.type === CourseObject.ladder) {
          ladders.push(def)
        } else if (child.userData.type === CourseObject.climber) {
          climbers.push(def)
        } else if (child.userData.type === CourseObject.timingGate) {
          timingGate = def
        }
      }
    }

    const path: IVector[] = this.pathPoints.map(pp => {
      return { x: Math.round(pp.x), y: Math.round(pp.y), z: Math.round(pp.z) }
    })

    // paths.push({
    //   name: 'Default',
    //   points: path
    // })
    const def: ICourse = {
      name: this.course.name,
      author: this.course.author,
      code: this.course.code,
      mtsSets: this.course.mtsSets,
      timingGate: timingGate,
      pipes: pipes,
      tees: tees,
      gates: gates,
      ladders: ladders,
      climbers: climbers,
      panels: panels,
      path: path,
      paths: paths,
    }

    console.log(`Saving Course: (${this.course.code})`)
    await setDoc(this.courseDoc(), def)

    if (this.code !== this.course.code) {
      this.$router.replace(`/editor/${this.course.code}`)
    }
  }


  private renderSize = new Vector2()
  private resizeCanvasToContainerSize() {
    this.renderer.getSize(this.renderSize)
    const h = window.innerHeight - 54
    const w = window.innerWidth

    if (this.renderSize.width !== w || this.renderSize.height !== h) {
      this.renderer.setSize(w, h, true)
      this.camera.aspect = w / h
      this.camera.updateProjectionMatrix()
    }
    this.renderer.getSize(this.pathMat.resolution)

  }

  private pointerPos = new Vector2()
  private updatePointPosition(event: PointerEvent) {
    this.pointerPos.x = ((event.clientX) / this.renderSize.width) * 2 - 1
    this.pointerPos.y = -((event.clientY - 54) / this.renderSize.height) * 2 + 1
  }

}

